import './index.scss'

import React from 'react'
import T from 'prop-types'
import BEMHelper from 'react-bem-helper'

import { Container } from '@components'
import Price from '../Price'
import {
  DOWNLOAD_FROM_APPLE,
  DOWNLOAD_FROM_GOOGLE,
} from '../../data/download-links'

const bem = new BEMHelper('propaganda')

const defaultTitle = (
  <>
    Bli en bedre kokk med Foodsteps-appen. Last ned i{' '}
    <a href={DOWNLOAD_FROM_APPLE}>AppStore</a> eller{' '}
    <a href={DOWNLOAD_FROM_GOOGLE}>Google Play</a>.
  </>
)

export default function Propaganda({
  products,
  title = defaultTitle,
  preamble,
  fluid,
  tight,
}) {
  const Wrapper = fluid ? 'section' : Container

  return (
    <Wrapper node="section" {...bem('', { tight })}>
      <div {...bem('content')}>
        <h2 {...bem('title')}>{title}</h2>
        {preamble ? <p {...bem('preamble')}>{preamble}</p> : null}
      </div>

      <div {...bem('price')}>
        <Price id="full" products={products} />

        <p {...bem('disclaimer')}>
          Prisen du får i introtilbudet gjelder inntil du avslutter
          abonnementet.
        </p>
      </div>
    </Wrapper>
  )
}

Propaganda.propTypes = {
  products: T.arrayOf(T.object).isRequired,
  title: T.string,
  preamble: T.string,
  fluid: T.bool,
  tight: T.bool,
}
