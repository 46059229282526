import './index.scss'

import React from 'react'
import { find } from 'lodash'
import T from 'prop-types'
import BEMHelper from 'react-bem-helper'
import { useQuery } from '@apollo/react-hooks'

import { Icon } from '@components'
import Button from '../Button'
import subscriptions from '../../data/subscriptions'
import gql from 'graphql-tag'

const bem = new BEMHelper('price')

export default function Price({ id = 'full', hideAction, products, campaign }) {
  const { data: productsAndPromotions } = useQuery(gql`
    {
      products {
        id
        variants {
          id
          promotion {
            id
            eligible
          }
        }
      }
    }
  `)

  const product = find(products, ({ id: productId }) => productId === id) || {}

  const {
    includes,
    variants: [variant],
    variants: [{ price, months }],
  } = product

  const { cta, preamble } = subscriptions[id]

  const eligibleForPromotion = find(
    find(productsAndPromotions?.products, { id })?.variants,
    {
      promotion: {
        id: variant?.promotion?.id
      }
    }
  )?.promotion?.eligible

  const promotion = (variant.promotion && eligibleForPromotion && !campaign) ? variant.promotion : null
  const name = promotion ? promotion.title : product.title
  const specialOffer = promotion && promotion.description

  return (
    <div {...bem('', { [id]: id, 'hide-action': hideAction })}>
      <h2 {...bem('name')}>{name}</h2>

      {preamble && <p {...bem('preamble')}>{preamble}</p>}
      {Boolean(price) && <strong {...bem('price')}>{(campaign?.price || price) / 100},-</strong>}
      {Boolean(price) && (
        <span {...bem('monthly')}>
          {months === 1 ? 'i måneden' : `pr. ${months} mnd.`}
          {campaign ? ` i ${campaign.months} måned${campaign.months !== 0 ? 'er' : ''}` : null}
        </span>
      )}

      {campaign && campaign.planPrice ? <p {...bem('special-offer', 'ineligible')}>
        Etter den rabatterte perioden fortsetter abonnementet til spesialpris på {campaign.planPrice/100},- {months === 1 ? 'i måneden' : `pr. ${months} mnd.`}.
      </p> : null}

      {campaign && !campaign.planPrice ? <p {...bem('special-offer', 'ineligible')}>
        Etter den rabatterte perioden fortsetter abonnementet til ordinær pris på {price/100},- {months === 1 ? 'i måneden' : `pr. ${months} mnd.`}.
      </p> : null}

      {!campaign ? <>
        {specialOffer && <p {...bem('special-offer')}>{specialOffer}</p>}
        {variant.promotion && !eligibleForPromotion ? (
          <p {...bem('special-offer', 'ineligible')}>
            Tilbudet om 30 dagers gratis prøveperiode er kun for nye abonnenter.
          </p>
        ) : null}
      </> : null}

      {includes && (
        <ul {...bem('list')}>
          {includes.map((item, index) => (
            <li key={index} {...bem('list-item')}>
              <Icon icon="check" {...bem('check')} />
              {item}
            </li>
          ))}
        </ul>
      )}

      {!hideAction && (
        <Button
          to="/registrer"
          state={{ subscription: id }}
          primary
          full
          spaceless
          {...bem('action')}
        >
          {cta}
        </Button>
      )}
    </div>
  )
}

Price.propTypes = {
  id: T.string,
  hideAction: T.bool,
  products: T.arrayOf(T.object).isRequired,
  campaign: T.shape({
    price: T.number,
    months: T.number
  })
}
